export const EXPERIMENTS = {
  SHOW_ADD_COUPON_LABEL: 'specs.membership.ShowAddCouponLabel',
  USE_BLOCKS_SINGLE_PLAN_WIDGET: 'specs.membership.UseBlocksSinglePlanWidget',
  USE_BLOCKS_PACKAGE_PICKER_PAGE: 'specs.membership.UseBlocksPackagePickerPage',
  ALERT_MODALS: 'specs.membership.AlertModals',
  USE_WARMUP_DATA: 'specs.membership.UseWarmupData',
  FORCE_MEMBERS_AREA_INSTALL: 'specs.membership.ForceMemberAreaInstall',
  FORCE_MEMBERS_AREA_NO_INSTALL: 'specs.membership.ForceMemberAreaNoInstall',
  DO_NOT_COLLAPSE_PAYMENTS_WIDGET: 'specs.membership.DoNotCollapsePaymentsWidget',
  PRICE_FORMATTER: 'specs.membership.PriceFormatter',
  ACCEPT_PAYMENTS_ERROR: 'specs.membership.AcceptPaymentsError',
  DEMO_PLANS_TRANSLATED: 'specs.membership.demoPlansTranslated',
  BENEFITS_ALIGNMENT: 'specs.membership.BenefitsAlignment',
  BLOCKS_LAYOUT_CUSTOMIZATION: 'specs.membership.BlocksLayoutCustomization',
  HEADLESS_SUPPORT: 'specs.membership.HeadlessSupport',
  PLAN_NAME_LINES: 'specs.membership.PlanNameLines',
  INSTALL_SPLIT_PAGES: 'specs.membership.InstallSplitPages',
  APPLY_INSTALLATION_FIX: 'specs.membership.ApplyInstallationFix',
  BLOCKS_ADD_WIDGET_PROGRESS: 'specs.membership.BlocksAddWidgetProgress',
  BLOCKS_SET_CONTROLLER_STATE_ON_LOAD: 'specs.membership.BlocksSetControllerStateOnLoad',
  ROUTER: 'specs.membership.EnableRouter',
};
